'use strict';
// Source: ../shared/public_html/assets/js/pure/css.js
(function(fly)
{
  var css = function(element)
  {
    if (!(this instanceof css))
    {
      return new css(element);
    }

    this.element = element;
  };

  css.prototype = {

    offsetLeft: function()
    {
      var xPos = this.element.offsetLeft,
        tempEl = this.element.offsetParent;

      while (tempEl != null)
      {
        xPos += tempEl.offsetLeft;
        tempEl = tempEl.offsetParent;
      }
      return xPos;
    },

    getPosition: function ()
    {
      var xPosition = 0;
      var yPosition = 0;
      var element = this.element;

      while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }
      return { x: xPosition, y: yPosition };
    },

    getPositionWithNull: function()
    {
      var xPosition = null;
      var yPosition = null;
      var element = this.element;

      if (element.offsetWidth)
      {
        while (element)
        {
          xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
          element = element.offsetParent;
        }
      }
      return { x: xPosition, y: yPosition };
    }
  };

  fly.css = css;

})( window.fly = window.fly || {} );;

// Source: ../shared/public_html/assets/js/pure/dom.js
(function(fly)
{
  var dom = function(element)
  {
    if (!(this instanceof dom))
    {
      return new dom(element);
    }

    this.element = element;
  };

  dom.prototype = {

    /**
     * Checks that element is inside parentElement
     * @param parentElement
     * @param limit - max number of check iterations
     */
    isInside: function (parentElement, limit)
    {
      var i = 0;
      var element = this.element;

      limit = limit || 1000;

      while (i < limit && element)
      {
        if (element === parentElement)
        {
          return true;
        }
        element = element.parentNode;
        i++;
      }
      return false;
    },

    hasContainsAllClasses: function (element, classList)
    {
      if (!classList || !classList.length) return true;

      for (var i in classList) {
        if (!element.classList.contains(classList[i])) return false;
      }
      return true;
    },

    isInsideCond: function (tagName, classArray, limit)
    {
      var i = 0;
      var element = this.element;

      limit = limit || 20;

      while (i < limit && element)
      {
        if (element.nodeName === tagName.toUpperCase() && this.hasContainsAllClasses(element, classArray))
        {
          return true;
        }
        element = element.parentNode;
        i++;
      }
      return false;
    }
  };

  fly.dom = dom;

})( window.fly = window.fly || {} );;

// Source: ../shared/public_html/assets/js/pure/inputAutoSize.js
(function(fly)
{
  var inputAutoSize = function(element)
  {
    this.element = null;
    this.previewElement = null;
    this.computedStyle = null;
    this.values = {};
    this.lastValue = '';
    this.lastWidth = 0;

    this.init(element);
    this.watch();
  };

  inputAutoSize.prototype = {

    getBody: function()
    {
      return document.getElementsByTagName('body')[0];
    },

    init: function(element)
    {
      if (!(element instanceof HTMLElement)) return false;

      this.element = element;
      this.previewElement = this.newElement();
      this.getBody().appendChild(this.previewElement);
      this.compute();

      setTimeout(function(){
        element.className += ' inputAutoSize';
      }, 100);

      return true;
    },

    sync: function()
    {
      if (this.lastValue === this.element.value) return false;

      this.lastValue = this.element.value;
      this.previewElement.innerHTML = this.element.value;

      this.refresh();
    },

    watch: function()
    {
      var self = this;
      this.element.addEventListener('keydown', function(e)
      {
        setTimeout(function()
        {
          self.sync();
        }, 0);
      });

      this.sync();
    },

    attrsNative: ['font-family', 'font-size', 'font-weight', 'font-style',
      'letter-spacing', 'text-transform', 'word-spacing', 'text-indent',
      'box-sizing', 'border-right-width', 'border-left-width', 'border-left-style', 'border-right-style',
      'padding-left', 'padding-right', 'margin-left', 'margin-right'],

    attrs: ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent', 'boxSizing', 'borderRightWidth', 'borderLeftWidth', 'borderLeftStyle', 'borderRightStyle', 'paddingLeft', 'paddingRight', 'marginLeft', 'marginRight'],

    newElement: function()
    {
      var element = document.createElement('span');
      element.setAttribute('style', 'white-space:pre; position:fixed; top: -999px; left:0; z-index: -1; opacity: 0;');
      return element;
    },

    isElement: function()
    {
      return this.element !== null;
    },

    getValue: function(key)
    {
      return this.values[key];
    },

    setValue: function(key, value)
    {
      this.values[key] = value;
    },

    css: function(key, value)
    {
      this.previewElement.style[key] = value;
    },

    refresh: function()
    {
      var width = this.previewElement.clientWidth;

      if (width !== this.lastWidth)
      {
        this.lastWidth = width;
        this.element.style.width = width + 'px';
      }
    },

    compute: function()
    {
      var self = this;
      if (!this.isElement()) return false;

      this.computedStyle = window.getComputedStyle(this.element, null);

      var changeCounter = 0;
      this.attrs.forEach(function(e, k)
      {
        var newValue = self.computedStyle.getPropertyValue( self.attrsNative[k] );
        var oldValue = self.getValue(e);

        if (newValue !== oldValue)
        {
          self.setValue(e, newValue);
          self.css(e, newValue);
          changeCounter++;
        }
      });

      if (changeCounter) this.refresh();
    }
  };

  fly.inputAutoSize = inputAutoSize;

})( window.fly = window.fly || {} );;

// Source: ../shared/public_html/assets/js/pure/router.js
(function(fly)
{
  var TOKEN_KEY_NAME = 'token';

  var router = function()
  {
    var getPageTitle = function ()
    {
      return document.getElementsByTagName('title')[0].innerHTML;
    };

    var removeSessionToken = function ()
    {
      //var tokenPattern = /\?token=.*/;
      var tokenPattern = new RegExp('(\\?|&)' + TOKEN_KEY_NAME + '=.*');
      var url = window.location.href;

      if (url.match(tokenPattern))
      {
        url = url.replace(tokenPattern, '');
        url = url.replace(/^http.?:\/\/[a-z0-9\.\-_]+\//, '/');
        url = window.location.protocol + '//' + window.location.host + url;

        try
        {
          window.history.replaceState({}, getPageTitle(), url);
        } catch(e)
        {
          window.history.pushState({}, getPageTitle(), url);
        }
      }
    };

    //init
    (function ()
    {
      removeSessionToken();
    })();

    return {
      removeSessionToken: removeSessionToken
    };
  };

  fly.router = new router();

})( window.fly = window.fly || {} );;

// Source: ../shared/public_html/assets/js/pure/textAnimation.js
(function(fly)
{
  var NODE_TYPE_TEXT = 3;

  var textAnimation = function(wrapper)
  {
    /**
     * @type {HTMLElement}
     */
    this.wrapper = wrapper;

    /**
     * @type {Array}
     */
    this.textNodes = [];

    /**
     * @type {Array}
     */
    this.textContent = [];

    /**
     * @type {HTMLElement}
     */
    this.caret = null;

    /**
     * @type {boolean}
     */
    this.animationEnded = false;
  };

  textAnimation.prototype =
  {
    createCaret: function ()
    {
      var caret = document.createElement('span');
      caret.classList.add('text-animation-caret');
      return caret;
    },

    getTextNodes: function(node, top)
    {
      var nodes = node.childNodes,
        tmpNode = null;

      for(var i = 0, length = nodes.length; i < length; i++)
      {
        tmpNode = nodes[i];

        if (tmpNode.nodeType == NODE_TYPE_TEXT)
        {
          if (top && (i == 0 || i == (length - 1)))
          {
            if (tmpNode.textContent.match(/^\s*$/))
            {
              continue;
            }
          }

          this.textNodes.push(tmpNode);
        }
        else
        {
          this.getTextNodes(tmpNode);
        }
      }

    },

    cloneTextContent: function()
    {
      for (var i = 0, length = this.textNodes.length; i < length; i++)
      {
        this.textContent.push( this.textNodes[i].textContent.replace(/\s+/g, ' ').replace(/ +/g, ' ') );
      }
    },

    clearTextContent: function()
    {
      for (var i = 0, length = this.textNodes.length; i < length; i++)
      {
        this.textNodes[i].textContent = '';
      }
    },

    printContent: function(delay)
    {
      var i = 0,
        j = 0,
        self = this;
      var textContentLength = this.textContent.length;

      this.animationEnded = false;

      var $interval = setInterval(function()
      {
        if (i == textContentLength)
        {
          self.animationEnded = true;
          clearInterval($interval);
          return;
        }

        self.textNodes[i].textContent += self.textContent[i][j];
        self.textNodes[i].parentNode.appendChild(self.caret);

        j++;
        if (j == self.textContent[i].length)
        {
          j = 0;
          i++;
        }
      }, delay);

      return this;
    },

    init: function (caret)
    {
      if (!caret)
      {
        caret = this.createCaret();
      }
      this.caret = caret;

      this.getTextNodes(this.wrapper, true);
      this.cloneTextContent();

      return this;
    },

    animate: function (delay)
    {
      this.clearTextContent();
      this.printContent(delay);

      return this;
    },

    $wait: function ($callback)
    {
      var self = this;

      var $interval = setInterval(function ()
      {
        if (!self.animationEnded) return;

        clearInterval($interval);
        $callback();
      }, 30);
    }

  };

  fly.textAnimation = textAnimation;

})( window.fly = window.fly || {} );